import React from 'react';
import { Link } from 'react-router-dom';

import solarLogo from './logos/logo.png';
import askomaLogo from './logos/askoma.png';
import { isAskoma } from '../../utils';

import './index.scss';

/**
 * Renders Solar Manager Logo for all pages
 */
const Logo = () => (
  <div className="m-login__log">
    <Link to="/">
      <img src={isAskoma() ? askomaLogo : solarLogo} alt="logo_solar_manager" className="topBarLogo" />
    </Link>
  </div>
);

export default Logo;
