import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import i18n from '../../../../i18n';
import { CIRCLE_CHART_COLORS, DEFAULT_FONT, tooltip } from '../../constants';

import './index.scss';

const getEmptySeries = (color, sizes) => ({
  data: [100],
  ...sizes,
  colors: [color],
  type: 'pie',
  borderWidth: 0,
  showInLegend: false,
  showInLabel: false,
  tooltip: {
    enabled: false
  },
  enableMouseTracking: false,
  animation: false,
  zIndex: 0
});

const CircleChart = (props) => {
  const { titleKey, chartData } = props;
  const { lastMonth, lastYear, overall } = chartData || {};

  const chartRef = useRef(null);

  const series = [
    getEmptySeries(CIRCLE_CHART_COLORS.OVERALL_EMPTY_COLOR, { size: '100%', innerSize: '85%' }),
    getEmptySeries(CIRCLE_CHART_COLORS.ONE_YEAR_EMPTY_COLOR, { size: '70%', innerSize: '85%' }),
    getEmptySeries(CIRCLE_CHART_COLORS.ONE_MONTH_EMPTY_COLOR, { size: '40%', innerSize: '80%' }),
    {
      allowPointSelect: false,
      type: 'pie',
      size: '40%',
      innerSize: '70%',
      data: [{ y: lastMonth, name: i18n.t('1month') }],
      colors: [CIRCLE_CHART_COLORS.ONE_MONTH_ACTIVE_COLOR],
      endAngle: (lastMonth / 100) * 360,
      name: i18n.t('total')
    },
    {
      allowPointSelect: false,
      type: 'pie',
      size: '70%',
      innerSize: '80%',
      data: [{ y: lastYear, name: i18n.t('1year') }],
      colors: [CIRCLE_CHART_COLORS.ONE_YEAR_ACTIVE_COLOR],
      endAngle: (lastYear / 100) * 360,
      name: i18n.t('total')
    },
    {
      allowPointSelect: false,
      type: 'pie',
      size: '100%',
      innerSize: '85%',
      data: [{ y: overall, name: i18n.t('overall') }],
      colors: [CIRCLE_CHART_COLORS.OVERALL_ACTIVE_COLOR],
      endAngle: (overall / 100) * 360,
      name: i18n.t('total')
    }
  ];

  return (
    <div className="circle-chart-wrapper">
      <HighchartsReact
        highcharts={Highcharts}
        ref={chartRef}
        options={{
          credits: { enabled: false },
          accessibility: { enabled: false },
          legend: {
            align: 'center',
            layout: 'vertical', // Вертикальне розташування елементів легенди
            className: 'circle-chart-legend',
            enabled: true,
            useHTML: true,
            symbolWidth: 0,
            symbolHeight: 0,
            floating: true,
            itemHiddenStyle: { color: CIRCLE_CHART_COLORS.itemHiddenStyle },
            itemHoverStyle: { color: CIRCLE_CHART_COLORS.itemHoverStyle },
            labelFormat: `
            <div style="display: flex; align-items: center; justify-content: space-between; width: 200px;">
              <div style="max-width:150px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                {name}
              </div>
              <div style="color: {color};">
                {y}%
              </div>
            </div>`,
            itemStyle: {
              color: CIRCLE_CHART_COLORS.itemStyleColor,
              fontSize: '18px',
              fontWeight: 'normal'
            }
          },
          title: {
            text: `<div class="title">${i18n.t(titleKey)}</div>`,
            useHtml: true,
            style: {
              color: CIRCLE_CHART_COLORS.titleColor,
              fontSize: '24px'
            }
          },
          chart: {
            backgroundColor: CIRCLE_CHART_COLORS.bgColor,
            margin: [50, 0, 100, 0],
            style: { fontFamily: DEFAULT_FONT }
          },
          tooltip: {
            valueSuffix: '%',
            ...tooltip
          },
          plotOptions: {
            pie: {
              borderRadius: 100,
              borderWidth: 0,
              colorByPoint: true,
              type: 'pie',
              size: '100%',
              innerSize: '70%',
              allowPointSelect: true,
              minSize: 200,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },
              showInLegend: true
            }
          },
          series
        }}
        containerProps={{ style: { height: '100%', width: '100%', position: 'absolute', top: '0', left: '0', paddingTop: 10 } }}
      />
    </div>
  );
};

CircleChart.propTypes = {
  titleKey: PropTypes.string.isRequired,
  chartData: PropTypes.instanceOf(Object)
};

export default CircleChart;
