import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Link } from 'react-router-dom';

import Tooltip from '../../Tooltip';

import i18n from '../../../i18n';

/**
 * Render function for React-Table. SubComponent for user detail info.
 * @memberof module:ReactTable
 * @param  {Object} props - React component properties
 * @param  {Object} props.original - props of the user details
 * @param  {string} props.original.first_name - users first name
 * @param  {string} props.original.last_name - users last name
 * @param  {string} props.original.email - users email
 * @param  {string} props.original.street - users street
 * @param  {string} props.original.city - users city
 * @param  {string} props.original.zip - users ZIP
 * @param  {string} props.original._id - users id
 * @param  {string} props.original.gateway - users pv-installer
 * @param  {string} props.original.userName - users name
 */
const UserInfoSub = ({ original: { first_name, last_name, email, street, city, zip, _id, gateway, userName } }) => (
  <div className="table-details">
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-6 col-lg-4 row justify-content-start">
          <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
            {i18n.t('firstName')}
          </div>
          <div className="col-6  table-row-details-td">{first_name}</div>
          {' '}
          <br />
          <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
            {i18n.t('lastName')}
          </div>
          <div className="col-6  table-row-details-td">{last_name}</div>
          {' '}
          <br />
          <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
            {i18n.t(userName ? 'username' : 'mail')}
          </div>
          <div className={`col-6  table-row-details-td ${userName ? '' : 'email-detail-table'}`}>
            {userName || <a href={`mailto:${email}`}>{email}</a>}
          </div>
        </div>
        <div className="col-md-6  col-lg-4 row justify-content-start">
          <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
            {i18n.t('street')}
          </div>
          <div className="col-6  table-row-details-td">{street}</div>
          {' '}
          <br />
          <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
            {i18n.t('city')}
          </div>
          <div className="col-6  table-row-details-td">{city}</div>
          {' '}
          <br />
          <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
            {i18n.t('zip')}
          </div>
          <div className="col-6  table-row-details-td">{zip}</div>
        </div>
        {gateway?.errors?.length > 0 && (
          <div className="col-md-6 col-lg-4 mr-auto row justify-content-start">
            <div className="col-4 col-lg-5 col-xl-4 table-row-details-head">
              {i18n.t('error')}
              :
            </div>
            <div className="col-6 table-row-details-td">
              {gateway.errors.map(({ code }) => (
                <div key={shortid.generate()} className="error_status_yes">
                  {code}
                  {i18n.exists(`error_${code}`) && <Tooltip title={i18n.t(`error_${code}`)} />}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {gateway && !gateway.productionAnalysis && (
        <Link to={`/dashboard/${_id}/`} className="dashboard-link">
          {i18n.t('dashbo')}
        </Link>
      )}
      {gateway?.productionAnalysis && (
        <div className="dashboard-link">
          <span>
            <Link to={`/dashboard/${_id}/`}>
              {i18n.t('dashbo')}
            </Link>
          </span>
          <span>
            <Link to={`/production-analysis/overview/${_id}`}>
              {i18n.t('analysis')}
            </Link>
          </span>
        </div>
      )}
    </div>
  </div>
);

UserInfoSub.propTypes = {
  original: PropTypes.instanceOf(Object).isRequired
};

export default UserInfoSub;
