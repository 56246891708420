import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';

import { setSelectedFiles, getDocumentationFiles, downloadFiles } from './store/slice';
import { closeModalWindow, DOWNLOAD_FILE_MODAL_ID, openModalWindow } from '../ModalWindow/slice';

import {
  getDocumentationFilesLoadingSelector,
  getDocumentationFilesSelector,
  getDocumentationSelectedFilesSelector
} from '../../redux-store/selectors/documentation';
import { getModalsDownloadFileDataIDSelector } from '../../redux-store/selectors/modals';

import { DocumentationTableHeader, UploadModal, LImitSizeModal, DeleteFilesModal } from './components';
import { Breadcrumbs, Preloader, TopBar } from '../../components';
import { DELETE_FILES_MODAL_ID } from './constants';
import { getColumns } from './columns';

import i18n from '../../i18n';

import './index.scss';
import { isInvitedUserHasReadOnlyPermissionSelector, isPvEmployeeInstallHasReadOnlyAccessSelector } from '../../redux-store/selectors/userDevices';
import { clearUserData, getUserInfo } from '../UserDetails/slice';

const CheckboxTable = checkboxHOC(ReactTable);

const Documentation = (props) => {
  const { match, myRoleType } = props;
  const { userId } = match?.params || {};
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (myRoleType !== 'end_user') {
      dispatch(getUserInfo({ userId }));
    }

    return () => {
      dispatch(clearUserData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const files = useSelector(getDocumentationFilesSelector);
  const isFilesLoading = useSelector(getDocumentationFilesLoadingSelector);
  const downloadFilesId = useSelector(getModalsDownloadFileDataIDSelector);
  const selectedRowsIds = useSelector(getDocumentationSelectedFilesSelector);
  const isReadOnlyPermission = useSelector(isInvitedUserHasReadOnlyPermissionSelector);
  const isPvEmployeeInstallHasReadOnlyAccess = useSelector(isPvEmployeeInstallHasReadOnlyAccessSelector);

  const [selectedAll, setSelectedAll] = useState(false);

  const columns = useMemo(() => getColumns(myRoleType, isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess), [myRoleType, isReadOnlyPermission, isPvEmployeeInstallHasReadOnlyAccess, userId]); // eslint-disable-line

  const crumbs = myRoleType === 'end_user' ? [
    { name: i18n.t('settings'), url: '/my-devices' },
    { name: i18n.t('documentationTitle') }
  ] : [
    { name: i18n.t('SMUs'), url: '/users' },
    { name: i18n.t('settings'), url: `/users/${userId}` },
    { name: i18n.t('documentationTitle') }
  ];
  const checkSelected = (key) => selectedRowsIds.includes(key);

  const handlerToggleAll = () => {
    if (selectedAll) dispatch(setSelectedFiles({ selectedFiles: [] }));
    else dispatch(setSelectedFiles({ selectedFiles: files.map(({ _id }) => _id) }));
    setSelectedAll(!selectedAll);
  };

  const handlerResetSelected = () => {
    dispatch(setSelectedFiles({ selectedFiles: [] }));
    setSelectedAll(false);
  };

  const handlerToggleSelection = (key) => {
    const [, id] = key.split('-');
    const isSelected = checkSelected(id);

    let data = [];
    if (isSelected) data = [...selectedRowsIds.filter((item) => item !== id)];
    else data = [...selectedRowsIds, id];
    dispatch(setSelectedFiles({ selectedFiles: data }));

    if (data.length === files.length) setSelectedAll(true);
    else setSelectedAll(false);
  };

  const checkboxProps = {
    selectWidth: 45,
    columns,
    data: files,
    className: '-highlight m-datatable__table',
    selectAll: selectedAll,
    selectType: 'checkbox',
    isSelected: checkSelected,
    toggleSelection: handlerToggleSelection,
    toggleAll: handlerToggleAll,
    getTheadThProps: () => ({ className: `checkbox-react-table-all ${files.length ? '' : 'selected-all-hidden'}` }),
    getTdProps: () => ({ className: 'checkbox-react-table' }),
    getTrProps: (allComponentProps, currentProps) => {
      if (!allComponentProps || !currentProps) return {};
      const isSelected = checkSelected(currentProps.original._id);

      return {
        style: {
          backgroundColor: isSelected ? '#33363e' : 'inherit'
        }
      };
    },
    noDataText: i18n.t('noFilesFound'),
    pageSize: 1000
  };

  const handlerDeleteFiles = () => dispatch(openModalWindow({ modalID: DELETE_FILES_MODAL_ID, data: { ids: selectedRowsIds } }));

  useEffect(() => {
    if (downloadFilesId) {
      dispatch(downloadFiles({ filesIds: [downloadFilesId] }));
      dispatch(closeModalWindow({ modalID: DOWNLOAD_FILE_MODAL_ID }));
    }
  }, [downloadFilesId, userId]);// eslint-disable-line

  useEffect(() => {
    if (userId) {
      dispatch(getDocumentationFiles({ userId }));
    }
  }, [userId]);// eslint-disable-line

  useEffect(() => {
    const isResetSelectedAll = !selectedRowsIds.length && selectedAll;

    if (isResetSelectedAll) {
      setSelectedAll(false);
    }
  }, [files, selectedRowsIds]);// eslint-disable-line

  return (
    <>
      <TopBar updateButton showNavigation />
      {isFilesLoading ? (<Preloader />) : (
        <div className="main-table-container documentation-wrapper">
          <UploadModal userId={userId} />
          <LImitSizeModal />
          <DeleteFilesModal />
          <Breadcrumbs crumbs={crumbs} />
          <DocumentationTableHeader
            filesCount={files.length}
            selectedRowsIds={selectedRowsIds}
            resetSelectedRows={handlerResetSelected}
            deleteFiles={handlerDeleteFiles}
            myRoleType={myRoleType}
            isReadOnlyPermission={isReadOnlyPermission || isPvEmployeeInstallHasReadOnlyAccess}
          />
          <CheckboxTable {...checkboxProps} />
        </div>
      )}
    </>
  );
};

Documentation.propTypes = {
  match: PropTypes.instanceOf(Object).isRequired,
  myRoleType: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

export default Documentation;
