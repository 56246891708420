import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues, initialize, destroy, change, Field } from 'redux-form';
import { createSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import ModalWindow from '../../../ModalWindow';
import { closeModalWindow, openModalWindow } from '../../../ModalWindow/slice';
import i18n from '../../../../i18n';
import { ReactComponent as UpdateSVG } from '../../../../assets/images/svg/update.svg';
import { ReactComponent as BoxSVG } from '../../../../assets/images/svg/box.svg';
import { ReactComponent as HistorySVG } from '../../../../assets/images/svg/history-linear.svg';
import { editTariffId as editTariffFormID, TARIFFS_LIST_ID } from '../../constants';
import { TariffFormCommonContent } from '../TariffFormCommonContent';
import { normalizeToDate } from '../../../../modals/DataExportModalWindow/utils';
import { CustomDateInput } from '../../../../components/ReduxFormFields';
import { required, validateDate } from '../../../validation';

const DATEPICKER_MASK = 'DD/MM/YYYY';

const formatDate = (value) => {
  if (Number.isInteger(value) && moment(value).isValid()) {
    return moment(value).format(DATEPICKER_MASK);
  }
  return value;
};

const formSelector = createSelector(
  getFormValues(editTariffFormID),
  (formValues) => formValues
);

const EditTariffWindow = (props) => {
  const { reset, handleSubmit, unit, myRoleType, user, initialValues, valid, submit, pristine } = props;
  const dispatch = useDispatch();
  const formValues = useSelector(formSelector) || {};
  const modal = useSelector((state) => state.modals[editTariffFormID]);

  const {
    importEnergy: { tariffType: tariffTypeImport },
    exportEnergy: { tariffType: tariffTypeExport },
    activeTab,
    isHelionUser,
    from
  } = formValues;

  const isFromToday = moment(from).isSame(moment(), 'day');

  const saveAsNewTariff = () => {
    dispatch(change(editTariffFormID, 'saveAsNewTariff', true));
    setTimeout(submit, 0);
  };

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: editTariffFormID }));
  const openHistory = () => dispatch(openModalWindow({ modalID: TARIFFS_LIST_ID }));

  const onTabSelect = (index) => {
    if (valid) dispatch(change(editTariffFormID, 'activeTab', index));
  };

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  useEffect(() => {
    if (initialValues || modal?.data?.formData) {
      dispatch(initialize(editTariffFormID, { ...initialValues, ...(modal?.data?.formData || {}) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues, modal?.data?.formData]);

  useEffect(() => () => dispatch(destroy(editTariffFormID)), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModalWindow modalID={editTariffFormID}>
      <div className="modal-header d-flex justify-content-between align-items-center">
        <h5
          className="modal-title"
          style={{
            fontSize: '18px',
            textTransform: 'uppercase'
          }}
        >
          {i18n.t('tariffSettings')}
        </h5>
        <button
          type="button"
          className="button button-third"
          onClick={openHistory}
        >
          <HistorySVG />
          {i18n.t('tariffHistory')}
        </button>
      </div>
      <div className="modal-body">
        <form
          id={editTariffFormID}
          onSubmit={handleSubmit}
          className="m-login__form m-form pop-up-form edit-tariff-form"
        >
          <Box
            className="date-picker pb-2"
          >
            <Typography
              className="inputLabel"
              variant="h6"
            >
              {i18n.t('from.label')}
            </Typography>
            <Field
              name="from"
              className="m-input"
              autoComplete="off"
              preventAuto
              placeholder={DATEPICKER_MASK}
              component={CustomDateInput}
              format={formatDate}
              normalize={normalizeToDate}
              validate={[required, validateDate]}
            />
          </Box>
          <TariffFormCommonContent
            formId={editTariffFormID}
            formValues={formValues}
            myRoleType={myRoleType}
            unit={unit}
            onTabSelect={onTabSelect}
            activeTab={activeTab}
            valid={valid}
            initialValues={initialValues}
            user={user}
            tariffTypeImport={tariffTypeImport}
            tariffTypeExport={tariffTypeExport}
            isHelionUser={isHelionUser}
          />
        </form>
      </div>
      <div className="modal-footer highlighted">
        <div className="w-100">
          <button
            type="button"
            onClick={handleOnClose}
            className="button button-secondary"
            form={editTariffFormID}
          >
            {i18n.t('cancel')}
          </button>
        </div>
        {!isFromToday && (
          <button
            type="button"
            disabled={!modal?.data?.formData && (pristine || !valid)}
            onClick={saveAsNewTariff}
            className="button button-secondary with-icon"
            form={editTariffFormID}
          >
            <BoxSVG />
            <span>
              {' '}
              {i18n.t('saveAsNewTariff')}
            </span>
          </button>
        )}
        <button
          type="submit"
          disabled={!modal?.data?.formData && (pristine || !valid)}
          className="button button-primary with-icon"
          form={editTariffFormID}
        >
          <UpdateSVG />

          <span>
            {' '}
            {i18n.t('updateCurrentTariff')}
          </span>
        </button>
      </div>
    </ModalWindow>
  );
};

EditTariffWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  myRoleType: PropTypes.string.isRequired,
  reset: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
  unit: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
  valid: PropTypes.bool.isRequired,
  submit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: editTariffFormID,
  shouldError: () => true
})(EditTariffWindow);

export default form;
