import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change, Field } from 'redux-form';
import PropTypes from 'prop-types';
import i18n from '../../../../../../i18n';
import ArrowUp from '../../../../../../assets/images/svg/ArrowUp.svg';
import './index.scss';
import { additionalFields } from '../../../../../../containers/DevicesTable/utils';
import { required } from '../../../../../../containers/validation';

const Opened = (props) => {
  const {
    data,
    handleToggleCollapse,
    switchTime,
    deviceType,
    filteredFieldsComponents,
    meta,
    switchIndex,
    waterHeaterType,
    mode,
    isCarExists,
    license,
    userApp
  } = props;
  const dispatch = useDispatch();
  const error = meta.error?.[switchIndex];
  const [, , , switchAction, ...rest] = filteredFieldsComponents;
  const isDisabledCloseArr = data.activeDays?.every((day) => day === 0) || error;

  useEffect(() => {
    filteredFieldsComponents.forEach((fieldID) => {
      const field = additionalFields[fieldID];
      if (field.switchAction?.checkIfEnabled(deviceType, data.switchMode) && field.switchAction?.defaultValue && !data[fieldID]) {
        const defaultValue = typeof field.switchAction.defaultValue === 'function' ? field.switchAction.defaultValue(data.switchMode) : field.switchAction.defaultValue;
        dispatch(change(meta.form, `${switchTime}.${fieldID}`, defaultValue));
      }

      if (fieldID === 'socLimit' && mode === 'add' && field.switchAction?.checkIfEnabled(deviceType, data.switchMode) && data[fieldID]) {
        const defaultValue = field.switchAction.defaultValue(data.switchMode);
        dispatch(change(meta.form, `${switchTime}.${fieldID}`, defaultValue));
      }
    });
  }, [data.switchMode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="opened__main">
      <div className="opened__container">
        <div className="opened-time__container">
          <div className="opened-time__container__range">
            <div className="opened-time__range">
              <Field
                name={`${switchTime}.from`}
                className="m-input opened__time-picker"
                shrink
                errorPosition="relative"
                {...additionalFields.from}
                forceError={error}
              />
            </div>
            <div className="opened-time__range">
              <Field
                name={`${switchTime}.to`}
                className="m-input opened__time-picker"
                {...additionalFields.to}
                forceError={error}
              />
            </div>
          </div>
        </div>
        <div className="opened-days__container">
          <div className="opened-days-active">
            <span>{i18n.t('activeDaysLabel')}</span>
            <Field
              name={`${switchTime}.activeDays`}
              {...additionalFields.activeDays}
            />
          </div>
        </div>
        <div className="opened-switch__container">
          <div className="opened-switch-action">
            <Field
              label={i18n.t('switchActionLabel')}
              name={`${switchTime}.${switchAction}`}
              {...additionalFields[switchAction]}
              waterHeaterType={waterHeaterType}
              deviceType={deviceType}
              placeholder={i18n.t('selectAction')}
              mode={mode}
              isCarExists={isCarExists}
              userApp={userApp}
              validate={[(value) => required(value)]}
              license={switchAction === 'switchMode' ? license : undefined}
            />
          </div>
        </div>
        {rest.map((fieldID) => (
          <Field
            key={fieldID}
            name={`${switchTime}.${fieldID}`}
            {...additionalFields[fieldID]}
            label={i18n.t(additionalFields[fieldID].label)}
            {...(additionalFields[fieldID]?.switchAction || {})}
            isCarExists={isCarExists}
            title={i18n.t(additionalFields[fieldID].label)}
          />
        ))}
      </div>
      <div className="opened-button">
        <img
          className={`opened-button__img ${isDisabledCloseArr ? 'is-disabled-img' : ''}`}
          src={ArrowUp}
          alt="Up"
          onClick={handleToggleCollapse}
        />
      </div>
    </div>
  );
};

Opened.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  handleToggleCollapse: PropTypes.func.isRequired,
  switchTime: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  filteredFieldsComponents: PropTypes.instanceOf(Array).isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
  switchIndex: PropTypes.number.isRequired,
  waterHeaterType: PropTypes.string,
  mode: PropTypes.string,
  userApp: PropTypes.string.isRequired,
  isCarExists: PropTypes.bool,
  license: PropTypes.instanceOf(Object).isRequired
};

export default Opened;
