import moment from 'moment';
import i18n from '../../../i18n';

export function formatEnergy() {
  const value = Math.round(this.value / 10) / 100;
  return `${value} kW`;
}

const formatDay = (dateTime) => {
  const todayMS = moment().startOf('day').valueOf();
  const currentMS = dateTime.startOf('day').valueOf(); // 86400000
  const differenceMS = currentMS - todayMS;

  if (differenceMS < 0) return dateTime.format('DD-MM-YYYY');
  if (differenceMS < 86400000) return i18n.t('today');
  if (differenceMS < 86400000 * 2) return i18n.t('tomorrow');
  if (differenceMS < 86400000 * 3) return i18n.t('afterTomorrow');
  return dateTime.format('DD-MM-YYYY');
};

let previousDayOfTick;
export function xAxisFormatter() {
  const dateTime = moment(this.value);
  const currentDayOfTick = dateTime.date();
  if (currentDayOfTick !== previousDayOfTick) {
    previousDayOfTick = currentDayOfTick;
    return `<span class="xAxisTimeLabel">${dateTime.format(
      'HH:mm'
    )}</span><div class="xAxisDayLabel" style="fill: #C6C8D2; opacity: 46%" fill="#C6C8D2" fill-opacity="46%">${formatDay(
      dateTime
    )}</div>`;
  }
  return `<span class="xAxisTimeLabel">${dateTime.format('HH:mm')}</span>`;
}

export function calcTickInterval(windowWidth) {
  // 72 ticks
  if (windowWidth >= 5000) {
    return 3600000; // 1 hours
  }
  // 36 ticks
  if (windowWidth >= 2500) {
    return 2 * 3600000; // 2 hours
  }
  // 24 ticks
  if (windowWidth >= 1800) {
    return 3 * 3600000; // 3 hours
  }
  // 18 ticks
  if (windowWidth >= 1350) {
    return 4 * 3600000; // 4 hours
  }
  // 12 ticks
  if (windowWidth >= 900) {
    return 6 * 3600000; // 6 hours
  }
  // 9 ticks
  if (windowWidth >= 675) {
    return 8 * 3600000; // 8 hours
  }
  // 6 ticks
  if (windowWidth >= 450) {
    return 12 * 3600000; // 12 hours
  }
  // 3 ticks
  return 24 * 3600000; // 24 hours
}
