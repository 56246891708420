import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { PIE_CHART_COLORS, DEFAULT_FONT, tooltip } from '../../constants';

import i18n from '../../../../i18n';

const PieChart = ({ data, title }) => (
  <HighchartsReact
    highcharts={Highcharts}
    options={{
      credits: { enabled: false },
      accessibility: { enabled: false },
      legend: {
        enabled: true,
        floating: true,
        verticalAlign: 'bottom',
        label: { enabled: false },
        labelFormat: `<span class="">
          <span style="font-weight:600;color:${PIE_CHART_COLORS.titleColor};margin-right:5px;margin-left:5px;">
            {y}
          </span>
          {name}
          </span>`,
        itemHiddenStyle: { color: PIE_CHART_COLORS.itemHiddenStyle },
        itemHoverStyle: { color: PIE_CHART_COLORS.itemHoverStyle },
        itemStyle: { color: PIE_CHART_COLORS.itemStyleColor, fontSize: '14px', fontWeight: 'normal', opacity: 1 }
      },
      title: {
        text: `<div class="title">${title}</div>`,
        useHtml: true,
        style: { color: PIE_CHART_COLORS.titleColor }
      },
      chart: {
        backgroundColor: PIE_CHART_COLORS.bgColor,
        margin: [50, 5, 90, 5],
        style: { fontFamily: DEFAULT_FONT }
      },
      tooltip: {
        valueDecimals: 0,
        ...tooltip
      },
      plotOptions: {
        pie: {
          borderWidth: 0,
          colorByPoint: true,
          type: 'pie',
          size: '100%',
          innerSize: '70%',
          allowPointSelect: false,
          minSize: 200,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: true
        }
      },
      series: [{
        type: 'pie',
        data,
        name: i18n.t('total')
      }]
    }}
    containerProps={{
      style: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        left: '0'
      }
    }}
  />
);

PieChart.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired
};

export default PieChart;
