import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

import { CustomTextInput } from '../../../../../../components/ReduxFormFields';
import { required } from '../../../../../validation';
import emojiWithMustache from '../../../../../../assets/images/svg/emoji-with-mustache.svg';
import i18n from '../../../../../../i18n';

const LoginIntoAccount = ({ selectedBrand, isError, isLoading }) => (
  <div className={`login-into-account-wrapper ${isLoading ? 'visibility-hidden' : ''}`}>
    {['webviewExpert', 'webview'].includes(selectedBrand?.authType) ? (
      <>
        <div className={`content-wrapper ${selectedBrand?.authType === 'webview' ? 'mb-50px' : ''}`}>
          <img src={selectedBrand.icon} alt="icon" />
          <div className="content">
            <span className="title">{i18n.t('loginbtn')}</span>
            <span className="description">
              {i18n.t(
                selectedBrand?.authType === 'webview' ? 'addCarWebviewMode' : 'easilyLinkYourCar',
                { carName: selectedBrand.label }
              )}
            </span>
          </div>
        </div>
        {selectedBrand?.authType === 'webviewExpert' && (
          <>
            <div className="horizontal-line" />
            <div className="content-wrapper mb-50px">
              <img src={emojiWithMustache} alt="icon" />
              <div className="content">
                <span className="title">{i18n.t('manuallyLinkYourTesla')}</span>
                <span className="description">{i18n.t('provideYourCreds')}</span>
              </div>
            </div>
          </>
        )}
      </>
    ) : (
      <div className="content-wrapper">
        <img src={selectedBrand.icon} alt="icon" />
        <div className="content">
          <span className="title">{i18n.t('loginbtn')}</span>
          <span className="description">{i18n.t('provideYourCreds')}</span>

          <form id="loginIntoAccount" className="creds-form">
            <div className="field-wrapper">
              <Field
                label={i18n.t('userName')}
                component={CustomTextInput}
                className="m-input"
                validate={[required]}
                name="username"
                required
              />
            </div>
            <Field
              label="pass"
              component={CustomTextInput}
              type="password"
              className="m-input"
              validate={[required]}
              name="password"
            />
            {isError && <span className="validation-error">{i18n.t('carLoginError')}</span>}
          </form>
        </div>
      </div>
    )}
  </div>
);

LoginIntoAccount.propTypes = {
  selectedBrand: PropTypes.instanceOf(Object).isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: 'loginIntoAccount'
})(LoginIntoAccount);

export default form;
