import React from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { range } from 'lodash';

import locales from './locales';
import Tooltip from '../../Tooltip';
import i18n from '../../../i18n';
import './index.scss';

const style = {
  maxWidth: '380px',
  width: '100%',
  height: '35px',
  paddingRight: '5px',
  background: 'transparent',
  borderBottom: '1px solid #5e6774',
  borderRadius: 0,
  color: '#fff',
  outline: 'none'
};

const DateTimePicker = (props) => {
  const {
    showTooltip,
    label,
    input: { onChange, value }
  } = props;
  const defaultDate = dayjs().add(1, 'day').set('hour', 6).set('minute', 0);
  const currentValues = value ? dayjs(value) : defaultDate;
  const dateFormat = 'YYYY.MM.DD HH:mm';
  const disabledDate = (current) => current && current < dayjs().startOf('day');

  const handleChange = (newDate) => {
    const selectedMinutes = dayjs(newDate).minute();
    if (selectedMinutes % 15 !== 0) {
      const minutes = Math.round(selectedMinutes / 15) * 15;
      newDate = dayjs(newDate).set('minute', minutes);
    }
    onChange(dayjs(newDate).toISOString());
  };

  const onSelectDate = (date) => {
    handleChange(date);
  };

  const disabledDateTime = (date) => {
    const current = {
      date: dayjs().format('YYYY.MM.DD'),
      hour: dayjs().format('HH'),
      minute: dayjs().format('mm')
    };
    const selected = {
      date: dayjs(date).format('YYYY.MM.DD'),
      hour: dayjs(date).format('HH'),
      minute: dayjs(date).format('mm')
    };

    const isToday = (current.date === selected.date);
    const hour = dayjs().hour();
    const minute = dayjs().minute();

    if (isToday) {
      return {
        disabledHours: () => range(hour),
        disabledMinutes: () => range(current.hour === selected.hour ? minute : 0)
      };
    }

    return true;
  };

  return (
    <div className="custom-date-time-picker">
      <div className="flags-select-label">{i18n.t(label)}</div>
      {!!showTooltip && (
        <div className="switch-tooltip">
          <Tooltip title={i18n.t(showTooltip)} placement="right" />
        </div>
      )}
      <ConfigProvider locale={locales?.[i18n.language] || locales.english}>
        <DatePicker
          getPopupContainer={(trigger) => trigger.parentNode}
          popupClassName="custom-date-time-picker-dropdown"
          value={currentValues}
          defaultValue={defaultDate}
          style={style}
          disabledTime={disabledDateTime}
          format={dateFormat}
          suffixIcon={null}
          onSelect={onSelectDate}
          onChange={handleChange}
          showTime
          disabledDate={disabledDate}
          showNow={false}
          allowClear={false}
          minuteStep={15}
        />
      </ConfigProvider>
    </div>
  );
};

DateTimePicker.propTypes = {
  showTooltip: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  input: PropTypes.instanceOf(Object).isRequired
};

export default DateTimePicker;
